import React, { useState } from "react";
import { Typography, Container, Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Box from "@material-ui/core/Box";
import { BackButtonContainer } from "../paymentMethod/styles";
import Text from "../../textComponent/text";
import CreditsCard from "assets/images/credit-card.png";
import Cards from "react-credit-cards-2";
import { Modal } from "@mui/material";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useSelector } from "react-redux";
import paymentService from "../../../services/paymentService";
import ButtonCommom from "../../common/Button";
import { MdChevronLeft } from "react-icons/md";
import { usePayment } from "context/payment.context";
import SpinnerLoading from "components/common/spinnerLoading/SpinnerLoading";
import { isMobile } from "utils/isMobile";

const CreditCardPayment = () => {
	const { setType, data, isCardAndPlanUpdate, subscriptionCurrent } = usePayment();

	const idPlan = data?.id;

	const user = useSelector((state) => state.me.me);
	const businessInfor = useSelector((state) => state.business.business);
	const cardUpdate = subscriptionCurrent.current_plan === data.title;

	const [cvc, setCvc] = useState("");
	const [cpf, setCpf] = useState("");
	const [expiry, setExpiry] = useState("");
	const [focused, setFocused] = useState("");
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [error, setError] = useState(false);

	const handleClose = () => {
		if (error) {
			setOpen(false);
			setError(false);
		} else {
			setType("ActualPlan");
		}
		setError(false);
		setOpen(false);
	};

	const me = user;
	const business = businessInfor;

	const state = {
		initialValues: isCardAndPlanUpdate
			? {
					recipient_id: business?.id,
					prit_plan_id: idPlan,
					card: {
						card_holder_name: name || me?.name || "",
						card_expiration_date: expiry.replace(/\D/g, ""),
						card_number: number,
						card_cvv: cvc,
					},
			  }
			: {
					recipient_id: business?.id,
					prit_plan_id: idPlan,
					card: {
						card_holder_name: name || me?.name || "",
						card_expiration_date: expiry.replace(/\D/g, ""),
						card_number: number,
						card_cvv: cvc,
					},
					client: {
						name: me?.name || "",
						email: me?.email || "",
						document_number: cpf.replace(/\D/g, ""),
						phone: {
							ddd: me?.phone?.slice(3, 5) || "",
							number: me?.phone?.slice(5, 14) || "",
						},
						address: {
							street: business?.address?.street || "",
							complementary: business?.address?.unit || "",
							street_number: business?.address?.number || "",
							neighborhood: business?.address?.neighborhood || "",
							city: business?.address?.city || "",
							state: business?.address?.state || "",
							zipcode: business?.address?.postalCode || "",
							country: business?.address?.country || "",
						},
					},
					doc_type: "cpf",
			  },
	};

	const fetchPaymentRecurrenceSubscription = async (data) => {
		setIsLoading(true);

		try {
			await paymentService.putPaySubscriptionCredit(data);

			setMessage("Plano alterado com sucesso!");
			setOpen(true);
		} catch (error) {
			const errorMessage = error.response?.data?.message || error.message;
			setMessage(errorMessage);
			setOpen(true);
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputFocus = (e) => {
		setFocused(e.target.name);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		let formattedValue = value;

		switch (name) {
			case "number":
				formattedValue = formattedValue.replace(/\D/g, "");
				setNumber(formattedValue.slice(0, 16));
				break;
			case "name":
				setName(formattedValue);
				break;
			case "expiry":
				formattedValue = formattedValue.replace(/[^0-9]/g, "");
				if (formattedValue.length > 2) {
					formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2);
				}
				setExpiry(formattedValue.slice(0, 5));
				break;
			case "cvc":
				setCvc(formattedValue);
				break;
			case "cpf":
				formattedValue = formattedValue.replace(/\D/g, "");
				formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
				formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
				formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
				setCpf(formattedValue);
				break;
			default:
				break;
		}
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "210px",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		pt: 2,
		px: 4,
		pb: 3,
		overflow: "auto",
		"&:focus": {
			outline: "none",
		},
	};

	return (
		<>
			<BackButtonContainer onClick={() => setType("AddressPlan")}>
				<MdChevronLeft color="#585858" size={35} />
				<span>Voltar</span>
			</BackButtonContainer>
			<Container
				maxWidth="sm"
				style={{
					background: "#fff",
					borderRadius: 3,
					border: 0,
					color: "white",
					minWidth: isMobile() ? "100vw" : "80%",
					minHeight: "100vh",
					padding: "0 30px",
				}}>
				<Text
					variant="h6"
					align="center"
					color="textSecondary"
					gutterBottom
					style={{ padding: "1rem 0", color: "#393762" }}>
					Informe os dados do seu cartão de crédito
				</Text>
				<Text align="center" component="p">
					Nesta opção, o pagamento da mensalidade será feito de forma recorrente.
				</Text>
				<Grid container display="flex" justifyContent="center" alignItems="center">
					<Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
						<img src={CreditsCard} alt="Cartão de crédito" style={{ width: "20%", margin: "5% 0 5% 0" }} />
					</Grid>
					<Grid item xs={12}>
						<Cards cvc={cvc} expiry={expiry} focused={focused} name={name} number={number} />
						<form noValidate autoComplete="off" style={{
							marginTop: "2rem",
						}}>
							<TextField
								InputProps={{ style: { borderRadius: 50 } }}
								error={number === ""}
								fullWidth
								margin="normal"
								label="Número do cartão"
								variant="outlined"
								type="number"
								name="number"
								value={number}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
							<TextField
								InputProps={{ style: { borderRadius: 50 } }}
								error={name === ""}
								fullWidth
								margin="normal"
								label="Nome"
								variant="outlined"
								type="text"
								name="name"
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
							<TextField
								InputProps={{ style: { borderRadius: 50 } }}
								error={expiry === ""}
								fullWidth
								margin="normal"
								label="Data de validade"
								variant="outlined"
								type="text"
								name="expiry"
								value={expiry}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
							<TextField
								InputProps={{ style: { borderRadius: 50 } }}
								error={cvc === ""}
								fullWidth
								margin="normal"
								label="CVC"
								variant="outlined"
								type="password"
								name="cvc"
								onChange={handleInputChange}
								onFocus={handleInputFocus}
								inputProps={{ maxLength: 3 }}
							/>
							<TextField
								InputProps={{ style: { borderRadius: 50 } }}
								error={cpf === ""}
								fullWidth
								margin="normal"
								label="CPF"
								variant="outlined"
								type="text"
								name="cpf"
								value={cpf}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
								inputProps={{ maxLength: 14 }}
							/>

							<ButtonCommom
								text={cardUpdate ? "TROCAR CARTÃO" : "ASSINAR PLANO"}
								onClick={() => {
									setIsLoading(true);
									setTimeout(() => {
										fetchPaymentRecurrenceSubscription(state.initialValues);
									}, 2000);
								}}
								style={{
									marginTop: "2rem",
									height: "45px",
									fontSize: "1rem !important",
								}}
								disable={
									isLoading ||
									number.length === 0 ||
									name.length === 0 ||
									expiry.length === 0 ||
									cvc.length === 0 ||
									cpf.length === 0
								}
							/>

							{isLoading && (
								<div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
									<SpinnerLoading loading={isLoading} />
								</div>
							)}
						</form>
					</Grid>
				</Grid>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description">
					<Box sx={{ ...style, width: "50%" }}>
						<Typography id="parent-modal-title" style={{ textAlign: "center", color: "#393762" }}>
							Assinatura OK
						</Typography>
						<p id="parent-modal-description" style={{ textAlign: "center" }}>
							{message};
						</p>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<button
								style={{
									backgroundColor: "#0074D9",
									color: "white",
									borderRadius: "10px",
									padding: "10px 20px",
									border: "none",
									cursor: "pointer",
									height: "5vh",
								}}
								onClick={() => handleClose()}>
								Ok
							</button>
						</div>
					</Box>
				</Modal>
			</Container>
		</>
	);
};
export default CreditCardPayment;
