import React, { useState } from "react";
import {
	Container,
	Grid,
	Box,
	TextField,
	Switch,
	FormControlLabel,
	Modal,
	Stack,
	Alert,
} from "@mui/material";

import { useSelector } from "react-redux";
import paymentService from "../../../services/paymentService";
import ButtonCommom from "../../common/Button";
import { BackButtonContainer } from "../paymentMethod/styles";
import { MdChevronLeft } from "react-icons/md";
import { usePayment } from "context/payment.context";
import Text from "../../textComponent/text";

const allDigitsSame = (value) => /^(\d)\1+$/.test(value);

const isValidCPF = (cpf) => {
	const cleanedCpf = cpf.replace(/\D/g, "");
	return cleanedCpf.length === 11 && !allDigitsSame(cleanedCpf);
};

const isValidCNPJ = (cnpj) => {
	const cleanedCnpj = cnpj.replace(/\D/g, "");
	return cleanedCnpj.length === 14 && !allDigitsSame(cleanedCnpj);
};

const Pix = () => {
	const { setType, data, pixQrCode, setPixQrCode } = usePayment();
	const idPlan = data?.id;

	const [cpf, setCpf] = useState("");
	const [error, setError] = useState("");
	const [cnpj, setCnpj] = useState("");
	const [isCnpjActive, setIsCnpjActive] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const user = useSelector((state) => state.me.me);
	const businessInfor = useSelector((state) => state.business.business);

	const me = user;
	const business = businessInfor;

	const handleCpfChange = (event) => {
		let value = event.target.value.replace(/\D/g, "");
		if (value.length > 11) value = value.slice(0, 11);
		const formattedCpf = value
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
		setCpf(formattedCpf);

		if (isValidCPF(formattedCpf)) {
			setError("");
		} else {
			if (value.length < 11) {
				setError("CPF deve ter 11 dígitos.");
			} else {
				setError("CPF inválido.");
			}
		}
	};

	const handleCnpjChange = (event) => {
		let value = event.target.value.replace(/\D/g, "");
		if (value.length > 14) value = value.slice(0, 14);
		const formattedCnpj = value
			.replace(/^(\d{2})(\d)/, "$1.$2")
			.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
			.replace(/\.(\d{3})(\d)/, ".$1/$2")
			.replace(/(\d{4})(\d)/, "$1-$2");
		setCnpj(formattedCnpj);

		if (isValidCNPJ(formattedCnpj)) {
			setError("");
		} else {
			if (value.length < 14) {
				setError("CNPJ deve ter 14 dígitos.");
			} else {
				setError("CNPJ inválido.");
			}
		}
	};

	const handleSwitchChange = (event) => {
		setIsCnpjActive(event.target.checked);
		setCpf("");
		setCnpj("");
		setError("");
	};

	const handlePayment = () => {
		setLoading(true);
		const body = {
			payment_subscription_type: "pix",
			recipient_id: business?.id,
			doc: isCnpjActive ? cnpj.replace(/\D/g, "") : cpf.replace(/\D/g, ""),
			doc_type: isCnpjActive ? "cnpj" : "cpf",
			email: me?.email || "appprit@gmail.com",
			name: me?.name || "Prit",
			phone: me?.phone || "+5516982099585",
			neighborhood: business?.address.neighborhood || "Jardim Botânico",
			city: business?.address.city || "Ribeirão Preto",
			state: business?.address.state || "SP",
			street: business?.address.street || "Av. Professor João Fiúsa",
			street_number: business?.address.number || 1901,
			zip_code: business?.address.postalCode || "14024250",
			plan_id: idPlan,
		};

		if (!cnpj && !cpf) {
			setError("Informe um CPF ou CNPJ válido.");
			return;
		}

		paymentService
			.postPaySubscription(body)
			.then((response) => {
        setPixQrCode(response.data.pixQrCode);
				setType("PixQrCode");
				setLoading(false);
			})
			.catch((error) => {
				let errorMessage = error.message;
				if (error.response && error.response.data) {
					errorMessage = error.response.data.message || error.response.data;
				}
				setError(errorMessage);
				setLoading(false);
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const isFormValid = isCnpjActive ? isValidCNPJ(cnpj) : isValidCPF(cpf);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "50%", md: "40%" },
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		p: 4,
		overflow: "auto",
	};

	return (
		<>
			<BackButtonContainer onClick={() => setType("PaymentMethod")}>
				<MdChevronLeft color="#585858" size={35} />
				<span>Voltar</span>
			</BackButtonContainer>
			<Container
				maxWidth="sm"
				sx={{
					backgroundColor: "#fff",
					borderRadius: 3,
					color: "#393762",
					minHeight: "100vh",
					padding: { xs: "16px", sm: "32px" },
				}}>
				<Text
					variant="mid"
					align="center"
					style={{ padding: "5% 0 2% 0", color: "#393762" }}>
					Informe os dados do seu PIX
				</Text>
				<Text align="center" style={{ marginBottom: "2%" }}>
					Nesta opção, o pagamento da mensalidade será feito de forma recorrente via PIX.
				</Text>

				<Box textAlign="center" sx={{ mb: 3 }}></Box>

				<form autoComplete="off">
					<Stack spacing={3}>
						<Grid container justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={8}>
								<TextField
									fullWidth
									label={`Insira o seu ${isCnpjActive ? "CNPJ" : "CPF"}`}
									variant="outlined"
									value={isCnpjActive ? cnpj : cpf}
									onChange={isCnpjActive ? handleCnpjChange : handleCpfChange}
									error={Boolean(error)}
									helperText={error}
									InputProps={{
										style: { borderRadius: "10px", padding: "5px 14px" },
									}}
									inputProps={{ maxLength: isCnpjActive ? 18 : 14 }}
								/>
							</Grid>
						</Grid>

						<Grid container justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={8}>
								<FormControlLabel
									control={
										<Switch
											checked={isCnpjActive}
											onChange={handleSwitchChange}
											color="primary"
											inputProps={{ "aria-label": "cnpjSwitch" }}
										/>
									}
									label="Inserir CNPJ"
									sx={{ color: "grey" }}
								/>
							</Grid>
						</Grid>

						<Box textAlign="bottom">
							<ButtonCommom
								text={isCnpjActive ? "SEGUIR COM CNPJ" : "SEGUIR COM CPF"}
								onClick={() => {
									handlePayment();
								}}
								style={{
									marginTop: "3%",
									width: "100%",
									fontSize: "1em",
									height: "5vh",
									borderRadius: "10px",
									fontFamily: 'poppins',
								}}
								disable={!isFormValid || loading}
							/>
						</Box>

						{error && (
							<Stack sx={{ width: "100%" }} spacing={2}>
								<Alert variant="outlined" severity="error">
									Ocorreu um erro: {error}
								</Alert>
							</Stack>
						)}
					</Stack>
				</form>

				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="pix-modal-title"
					aria-describedby="pix-modal-description">
					<Box sx={style}>
						<Text
							id="pix-modal-title"
							style={{ textAlign: "center", color: "#393762", mb: 2 }}>
							Pagamento PIX
						</Text>
						<Box id="pix-modal-description" sx={{ textAlign: "center", mb: 2 }}>
							<Text>{pixQrCode}</Text>
						</Box>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<ButtonCommom
								text="Copiar QR Code"
								onClick={() => navigator.clipboard.writeText(pixQrCode)}
								style={{
									backgroundColor: "#0074D9",
									color: "white",
									borderRadius: "10px",
									padding: "10px 20px",
									border: "none",
									cursor: "pointer",
									width: "100%",
									fontFamily: 'Poppins',
								}}
							/>
						</Box>
					</Box>
				</Modal>
			</Container>
		</>
	);
};

export default Pix;
