import React from "react";
import ProfilePritPlan from "../../components/profilePritPlan";
import Address from "../../components/profilePritPlan/adressPlan";
import PaymentMethod from "../../components/profilePritPlan/paymentMethod";
import CreditCard from "../../components/profilePritPlan/creditCardPayment";
import PixQrCode from "../../components/profilePritPlan/pixPayment/pixQrCode";
import Pix from "../../components/profilePritPlan/pixPayment/pix";
import ActualPlan from "../../components/profilePritPlan/actualPlan";
import { usePayment } from "context/payment.context";

const SchedulePlanPages = () => {
    const {type} = usePayment();
    if (type === 'ActualPlan') return <ActualPlan />;
	if (type === 'PritPlan') return <ProfilePritPlan />;
    if (type === 'PaymentMethod') return <PaymentMethod />;
    if (type === 'AddressPlan') return <Address />;
    if (type === 'CreditCardPayment') return <CreditCard />;
    if (type === 'Pix') return <Pix />;
    if (type === 'PixQrCode') return <PixQrCode />;

    return <ActualPlan />;
};

export default SchedulePlanPages;
