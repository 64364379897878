import React from "react";
import InputText from "./input-text";
import SelectDDI from "./select-ddi";
import { Controller } from "react-hook-form";
import InputTextArea from "./input-textarea";
import InputCheckbox from "./input-checkbox";
import InputSelect from "./input-select";
import { handlePhoneChange } from "utils";

import clsx from "clsx";
import "./style.scss";

const Inputs = (
	props = {
		control: null,
		type: "text",
		inputRef: null,
		nextRef: null,
		value: "",
		variant: "outlined",
		placeholder: "Placeholder Text",
		label: "Input Text",
		ddi: null,
		handleKeyDown: () => {},
		style: {},
		inputProps: {},
		error: true,
		className: "",
		required: false,
		name: "input",
		helper: null,
		minRows: 4,
		options: [],
		checked: false,
	},
	error = true
) => {
	const [showError, setShowError] = React.useState(null);
	const handleChange = (onChange) => (e) => {
		let formattedValue = e.target.value;
		if (props.type === "tel") {
			formattedValue = handlePhoneChange(e, Number(props.ddi) ?? 1);
		}

		switch (props.name) {
			case "expiry":
				formattedValue = formattedValue.replace(/[^0-9]/g, "");
				if (formattedValue.length > 2) {
					formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2);
				}
				e.target.value = formattedValue;
				break;
			case "cpf":
				formattedValue = formattedValue.replace(/\D/g, "");
				formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
				formattedValue = formattedValue.replace(/(\d{3})(\d)/, "$1.$2");
				formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
				e.target.value = formattedValue;
				break;
			case "creditCard":
				formattedValue = formattedValue.replace(/\D/g, "");
				formattedValue = formattedValue.replace(/(\d{4})(\d)/, "$1 $2");
				formattedValue = formattedValue.replace(/(\d{4})(\d)/, "$1 $2");
				formattedValue = formattedValue.replace(/(\d{4})(\d)/, "$1 $2");
				e.target.value = formattedValue;
				break;
			case "zipcode":
				formattedValue = formattedValue.replace(/\D/g, "");
				formattedValue = formattedValue.replace(/(\d{5})(\d)/, "$1-$2");
				e.target.value = formattedValue;
				break;
			default:
				break;
		}

		onChange(props.type === "checkbox" ? e.target.checked : e.target.value);
	};

	const selectTypeInput = (properties) => {
		if (props.type === "ddi") {
			return (
				<SelectDDI
					value={properties.value}
					handleChange={properties.handleChange}
					className={properties.className}
					style={props.style}
				/>
			);
		}
		if (props.type === "textarea") {
			return <InputTextArea {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "checkbox") {
			return <InputCheckbox {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		if (props.type === "select") {
			return <InputSelect {...properties} className={clsx(props.className, showError && error && "input-error")} />;
		}
		return <InputText {...properties} className={clsx(props.className, showError && error && "input-error")} />;
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "auto", gap: "2px", ...props.style }}>
			<Controller
				control={props.control}
				name={props.name}
				rules={props.required}
				defaultValue={props.value}
				render={({ field: { onChange, value }, fieldState: { isTouched, error } }) => {
					if (isTouched) setShowError(error);
					return selectTypeInput({
						label: props.label,
						value,
						variant: props.variant,
						type: props.type,
						placeholder: props.placeholder,
						inputRef: props.inputRef,
						handleChange: handleChange(onChange),
						handleKeyDown: props.handleKeyDown,
						style: props.style,
						inputProps: props.inputProps,
						className: props.className,
						showError: showError,
						mask: props.mask,
						options: props.options,
						defaultChecked: props.checked,
						checked: props.checked,
						onBlur: () => setShowError(error),
					});
				}}
			/>
			{props.helper && <small className="input-helper">{props.helper}</small>}
			{showError && error && <div className="label-error">{showError?.message}</div>}
		</div>
	);
};

export default Inputs;
