import React, { useState } from "react";
import { Typography, Container, Box, Stack, Alert, Modal } from "@mui/material";
import ButtonCommom from "../../common/Button";
import { usePayment } from "context/payment.context";
import { BackButtonContainer } from "../paymentMethod/styles";
import { MdChevronLeft } from "react-icons/md";
const PixQrCode = () => {
	const { setType, pixQrCode } = usePayment();

	const [copyPix, setCopyPix] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [startTimer, setStartTimer] = useState(false);

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(pixQrCode);
			console.log("navigator", navigator);
			setOpen(true);
			setCopyPix(true);
			if (!startTimer) setStartTimer(true);
		} catch (err) {
			setCopyPix(false);
			setError("Falha ao copiar o código PIX. Tente novamente.");
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "50%", md: "40%" },
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		p: 4,
		overflow: "auto",
	};

	return (
		<>
			<BackButtonContainer onClick={() => setType("Pix")}>
				<MdChevronLeft color="#585858" size={35} />
				<span>Voltar</span>
			</BackButtonContainer>
			<Container
				maxWidth="sm"
				sx={{
					backgroundColor: "#fff",
					borderRadius: 3,
					color: "#393762",
					minHeight: "100vh",
					padding: { xs: "16px", sm: "32px" },
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Typography
					variant="h5"
					align="center"
					color="textSecondary"
					gutterBottom
					sx={{ padding: "5% 0 2% 0", color: "#393762" }}>
					Pagamento via PIX
				</Typography>

				<Typography
					variant="subtitle2"
					align="center"
					color="textSecondary"
					component="p"
					sx={{ marginBottom: "2%", fontSize: "16px" }}>
					1. Copie o código abaixo
				</Typography>
				<Typography
					variant="subtitle2"
					align="center"
					color="textSecondary"
					component="p"
					sx={{ marginBottom: "10%", fontSize: "16px" }}>
					2. Pague via PIX Copia e Cola em qualquer aplicativo habilitado
				</Typography>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						padding: "16px",
						backgroundColor: "#f5f5f5",
						borderRadius: "8px",
						boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
						mb: 2,
						marginTop: "5%",
						cursor: "pointer",
						":hover": {
							backgroundColor: "#f0f0f0",
						},
					}}
					onClick={copyToClipboard}>
					<Typography
						variant="body1"
						sx={{
							wordBreak: "break-all",
							fontSize: "18px",
							color: "#393762",
							marginRight: "8px",
						}}>
						{pixQrCode}
					</Typography>
				</Box>

				{error && (
					<Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
						<Alert variant="outlined" severity="error">
							{error}
						</Alert>
					</Stack>
				)}

				<Typography
					variant="subtitle2"
					sx={{
						fontSize: "15px",
						fontWeight: "bold",
						textAlign: "center",
						color: "grey",
						marginTop: "3%",
					}}>
					Você tem até 10 minutos para fazer o pagamento.
				</Typography>

				<ButtonCommom
					text={copyPix ? "PIX copiado" : "Copiar código PIX"}
					onClick={copyToClipboard}
					disabled={copyPix}
					style={{
						marginTop: "10%",
						width: "100%",
						fontSize: "1em",
						height: "5vh",
						backgroundColor: copyPix ? "#4CAF50" : "#4A90E2",
						color: "#fff",
						borderRadius: "10px",
						cursor: "pointer",
					}}
				/>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="pix-modal-title"
					aria-describedby="pix-modal-description">
					<Box sx={style}>
						<Typography id="parent-modal-title" style={{ textAlign: "center", color: "#393762" }}>
							PIX
						</Typography>
						<p id="parent-modal-description" style={{ textAlign: "center" }}>
							Código PIX copiado com sucesso
						</p>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<button
								style={{
									backgroundColor: "#0074D9",
									color: "white",
									borderRadius: "10px",
									padding: "10px 20px",
									border: "none",
									cursor: "pointer",
								}}
								onClick={() => handleClose()}>
								Ok
							</button>
						</div>
					</Box>
				</Modal>
			</Container>
		</>
	);
};

export default PixQrCode;
