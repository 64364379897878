import React from "react";
import { Button as ButtonBase } from "@material-ui/core";
import "./styles.scss";

export default function Button({ variant, color, fullWidth, className, title, handleClick, disabled }) {
	return (
		<ButtonBase variant={variant} color={color} fullWidth={fullWidth} className={className} onClick={handleClick} disabled={disabled}>
			{title}
		</ButtonBase>
	);
}