import moment from "moment";
import api from "./api";
import apiPayout from "./apiPayout";

export const getBookings = () => {
	return api.get(
		"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
	);
};

export const getDatedBookings = (businessId, startTime, endTime) => {
	return api.get( "schedule/bookings/", {
		params : {
			business_id: businessId,
			start_time: moment(startTime).format('YYYY-MM-DD'),
			end_time: moment(endTime).format('YYYY-MM-DD'),
			start_time_greater: moment(startTime).add(1, 'days').format('YYYY-MM-DD'),
			end_time_greater: moment(endTime).format('YYYY-MM-DD')
		}
	});
};

export const getBookingPaymentDetailsById = (bookingTransactionId) => {
	return apiPayout.get(`payment/list/grouped/payments/${bookingTransactionId}`);
};

export const getBookingTransactionById = (bookingTransactionId) => {
	return	api.get(`booking_transaction/${bookingTransactionId}`);
};

export const patchDeleteSchedule = (bookingId) => {
	return api.put(`booking/${bookingId}`,
		{
			booking: {
				status: 'inactive',
			}
		}
	);
};

export const patchEditSchedule = (bookingId, data) => {
	return api.patch("booking_transaction/" + bookingId, data);
};

export const cancelSchedule = (bookingTransactionId, data) => {
	return api.put("booking_transaction/" + bookingTransactionId, data);
};

const scheduleService = {
	getBookings,
	getDatedBookings,
	patchDeleteSchedule,
	patchEditSchedule,
	getBookingTransactionById,
};

export default scheduleService;
