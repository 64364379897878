import React, { Fragment, useEffect, useState } from "react";
import { Container, Button } from "@material-ui/core";
import axios from "axios";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import businessAction from "../../../store/actions/businessAction";
import { MdChevronLeft } from "react-icons/md";
import { BackButtonContainer } from "../paymentMethod/styles";
import { usePayment } from "context/payment.context";
import { useConfig } from "context/config.context";

const AddressPlan = () => {
	const { setType } = usePayment();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { handleFeedback } = useConfig();

	const [cep, setCep] = useState("");
	const [logradouro, setLogradouro] = useState("");
	const [numero, setNumero] = useState("");
	const [complemento, setComplemento] = useState("");
	const [pontoReferencia, setPontoReferencia] = useState("");
	const [uf, setUf] = useState("");
	const [cidade, setCidade] = useState("");
	const [bairro, setBairro] = useState("");
	const [isFormValid, setIsFormValid] = useState(false);

	const businessInfor = useSelector((state) => state.business.business);

	useEffect(() => {
		if (cep.length === 8) {
			axios
				.get(`https://viacep.com.br/ws/${cep}/json/`)
				.then((response) => {
					const data = response.data;
					setLogradouro(data.logradouro);
					setUf(data.uf);
					setCidade(data.localidade);
					setBairro(data.bairro);
				})
				.catch((error) => {
					handleFeedback("Atenção", "Erro ao buscar o CEP. Por favor, verifique o CEP digitado.", "warning");
					console.error("Erro ao buscar o CEP:", error);
				});
		}
	}, [cep]);

	useEffect(() => {
		const isValid = cep && logradouro && numero && complemento && uf && cidade && bairro;
		setIsFormValid(isValid);
	}, [cep, logradouro, numero, complemento, uf, cidade, bairro]);

	const handleSave = async () => {
		const address = {
			address_postal_code: cep,
			address_street: logradouro,
			address_number: numero,
			address_unit: complemento,
			address_neighborhood: bairro,
			address_state: uf,
			address_city: cidade,
			address_country: "Brasil",
		};

		if (businessInfor && businessInfor.id) {
			try {
				handleFeedback("Sucesso", "Endereço atualizado com sucesso.", "success");
				dispatch(businessAction.updateBusinessAddress(businessInfor.id, address));
			} catch (error) {
				let errorMessage = "Ocorreu um erro desconhecido. Por favor, tente novamente.";

				if (error.response) {
					if (error.response.data && error.response.data.message) {
						errorMessage = error.response.data.message;
					} else {
						errorMessage = "Erro ao acessar a página. Verifique suas permissões.";
					}
				} else if (error.request) {
					errorMessage = "Não foi possível conectar ao servidor. Por favor, verifique sua conexão de internet.";
				} else {
					errorMessage = error.message;
				}
				handleFeedback("Erro", `Erro ao atualizar o endereço: ${errorMessage}`, "error");
			}
		}
	};

	return (
		<>
			<BackButtonContainer onClick={() => setType("PaymentMethod")}>
				<MdChevronLeft color="#585858" size={35} />
				<span>Voltar</span>
			</BackButtonContainer>
			<Container className={classes.container}>
				<div className={classes.formContainer}>
					<label htmlFor="cep" className={classes.label}>
						Endereço
					</label>
					<br />
					<form className={classes.form}>
						<div className={classes.formGroup}>
							<label htmlFor="cep" className={classes.label}>
								CEP
							</label>
							<input
								type="text"
								id="cep"
								value={cep}
								onChange={(e) => setCep(e.target.value)}
								maxLength="8"
								className={classes.input}
								placeholder="Digite o CEP"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="logradouro" className={classes.label}>
								Logradouro
							</label>
							<input
								type="text"
								id="logradouro"
								value={logradouro}
								onChange={(e) => setLogradouro(e.target.value)}
								className={classes.input}
								placeholder="Digite o logradouro"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="numero" className={classes.label}>
								Número
							</label>
							<input
								type="text"
								id="numero"
								value={numero}
								onChange={(e) => setNumero(e.target.value)}
								className={classes.input}
								placeholder="Digite o número"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="complemento" className={classes.label}>
								Complemento
							</label>
							<input
								type="text"
								id="complemento"
								value={complemento}
								onChange={(e) => setComplemento(e.target.value)}
								className={classes.input}
								placeholder="Digite o complemento"
							/>
							<small className={classes.smallText}>Caso não tenha, escreva &quot;sem complemento&quot;</small>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="pontoReferencia" className={classes.label}>
								Ponto de Referência
							</label>
							<input
								type="text"
								id="pontoReferencia"
								value={pontoReferencia}
								onChange={(e) => setPontoReferencia(e.target.value)}
								className={classes.input}
								placeholder="Digite o ponto de referência"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="uf" className={classes.label}>
								UF
							</label>
							<input
								type="text"
								id="uf"
								value={uf}
								onChange={(e) => setUf(e.target.value)}
								className={classes.input}
								placeholder="Digite a UF"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="cidade" className={classes.label}>
								Cidade
							</label>
							<input
								type="text"
								id="cidade"
								value={cidade}
								onChange={(e) => setCidade(e.target.value)}
								className={classes.input}
								placeholder="Digite a cidade"
							/>
						</div>
						<div className={classes.formGroup}>
							<label htmlFor="bairro" className={classes.label}>
								Bairro
							</label>
							<input
								type="text"
								id="bairro"
								value={bairro}
								onChange={(e) => setBairro(e.target.value)}
								className={classes.input}
								placeholder="Digite o bairro"
							/>
						</div>
					</form>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						style={{
							marginTop: "20px",
							borderRadius: "10px",
							height: "5vh",
							backgroundColor: isFormValid ? "#3688EB" : "gray",
							color: "#fff",
							textTransform: "none",
						}}
						onClick={() => {
							handleSave();
							setType("CreditCardPayment");
						}}
						disable={!isFormValid}>
						Salvar
					</Button>
				</div>
			</Container>
		</>
	);
};

export default AddressPlan;
