import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Container, CircularProgress } from "@material-ui/core";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import GlobalButton from "../../components/globalButton";
import meAction from "../../store/actions/meAction";
import subscriptionDataAction from "../../store/actions/subscriptionDataAction";
import businessAction from "../../store/actions/businessAction";
import Modal from "@mui/material/Modal";
import { PrivacyPolicy } from "../../components/utils/index";
import arrow from "../../assets/images/plansScreen/VectorArrow.svg";
import checked from "../../assets/images/plansScreen/VectorChecked.svg";
import businessService from "../../services/businessService";
import "../../styles/payment/payment.scss";
import { isMobile } from "../../utils/isMobile";
import { usePayment } from "context/payment.context";
import { BackButtonContainer } from "./paymentMethod/styles";
import { MdChevronLeft } from "react-icons/md";

const ProfilePritPlan = () => {
	const {
		setType,
		setData,
		setSelectedPlan,
		selectedPlan,
	} = usePayment();


	const [expanded, setExpanded] = useState(false);

	const subscriptionData = useSelector((state) => state.subscription_data.subscription_data);
	const subscriptionState = useSelector((state) => state.subscription_current);

	const business = useSelector((state) => state.business);
	const location = useLocation();

	const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;

	const pathParams = window.location.pathname.split("/");

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { token } = useParams();

	const [auth, setAuth] = useState(getAuth());
	const [open, setOpen] = useState(false);

	const _date = business.businessInfo?.createdAt ?? moment().format("YYYY-MM-DD");
	const formattedDate = new Date(_date).toISOString();
	const businessId = business.businessInfo ? business.businessInfo.id : 0;

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const validateBusinessId = async (id) => {
		if (!id) {
			const data = await businessService.getBusiness();

			if (!data) {
				return 0;
			}

			return data.data[0].id;
		}

		return id;
	};

	const levels = subscriptionState.subscription_current?.expiration === null;

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!pathParams || pathParams.length < 3) {
				if (user) {
					const tokenAuth = user.accessToken;
					const config = {
						headers: {
							Authorization: "Bearer " + tokenAuth,
						},
					};

					dispatch(meAction.getMe(config));
					dispatch(businessAction.getBusiness(config));
				} else {
					setAuth(getAuth());
					navigate("/welcome");
				}
			} else if (pathParams && pathParams.length > 3) {
				const tokenAuth = pathParams[3];
				const config = {
					headers: {
						Authorization: "Bearer " + tokenAuth,
					},
				};
				localStorage.setItem("accessToken", tokenAuth);
				dispatch(meAction.getMe());
				dispatch(businessAction.getBusiness(config));
			}
		});

		return () => unsubscribe();
	}, [auth, token]);

	useEffect(() => {
		const fetchSubscriptionData = async () => {
			if (businessId === 0) {
				try {
					if (!auth) {
						const authInstance = getAuth();
						setAuth(authInstance);
					}

					if (auth?.currentUser || pathParams) {
						const id = await validateBusinessId(businessId);

						dispatch(subscriptionDataAction.getSubscriptionData(formattedDate, id, levels));
						dispatch(subscriptionDataAction.getSubscriptionCurrent(id));
					}
				} catch (error) {
					console.error("Erro ao buscar os dados da assinatura:", error);
				}
			} else if ((auth?.currentUser || pathParams) && businessId) {
				await dispatch(subscriptionDataAction.getSubscriptionData(formattedDate, businessId, levels));
				await dispatch(subscriptionDataAction.getSubscriptionCurrent(businessId));
			}
		};
		
		fetchSubscriptionData();
	}, [business]);

	useEffect(() => {
		if (!selectedPlan) {
			setSelectedPlan("Mensal");
		}
	}, [selectedPlan, setSelectedPlan]);	

	const handlePeriodClick = (plan) => {
		setSelectedPlan(plan === selectedPlan ? null : plan);
	};

	const handleClick = (selectedPlan) => {
		setData({...selectedPlan, isCardAndPlanUpdate });
		setType("PaymentMethod");
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const sortedPlans = subscriptionData?.sort((a, b) => a.mountPrice - b.mountPrice);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		height: "80%",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		pt: 2,
		px: 4,
		pb: 3,
		overflow: "auto",
		"&:focus": {
			outline: "none",
		},
	};

	if (!subscriptionState.subscription_current) {
		return (
			<Container
				maxWidth="sm"
				style={{
					background: "#fff",
					borderRadius: 3,
					border: "none",
					color: "white",
					minWidth: "100vw",
					minHeight: "100vh",
					padding: "0 30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<CircularProgress />
			</Container>
		);
	}

	const subtitles = {
		iniciante: "Descubra o básico com nosso plano Iniciante.",
		básico: "Mais recursos, mais possibilidades.",
		avançado: "Máxima eficiência com recursos avançados.",
		profissional: "O pacote completo para profissionais.",
	};

	return (
		<>
			<BackButtonContainer onClick={() => setType("ActualPlan")}>
				<MdChevronLeft color="#585858" size={35} />
				<span>Voltar</span>
			</BackButtonContainer>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					margin: isMobile() ? "0 auto" : "0",
					width: isMobile() ? "97%" : "80%",
					padding: isMobile() ? "10px 5px" : "20px 10px",
					minHeight: "100vh",
					boxSizing: "border-box",
				}}>
				<h1 className="TitlePlans" style={{ textAlign: "center", marginBottom: "20px", marginTop: "20px" }}>
					Selecione seu Plano
				</h1>

				<section
					className="periodBox"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						maxWidth: "600px",
						margin: "16px 0",
					}}>
					<button
						className={selectedPlan === "Mensal" ? "buttonActivated" : "buttonDefault"}
						onClick={() => handlePeriodClick("Mensal")}>
						Mensal
					</button>
					<button
						className={selectedPlan === "Anual" ? "buttonActivated" : "buttonDefault"}
						onClick={() => handlePeriodClick("Anual")}>
						Anual
					</button>
				</section>

				<div
					className="planContainer"
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "16px",
						alignItems: "center",
						width: "100%",
						maxWidth: "800px",
						padding: "0 16px",
						overflow: "hidden",
						marginBottom: "1rem",
					}}>
					{sortedPlans
						.filter((plan) => (selectedPlan === "Mensal" ? plan.daysRef === 30 : plan.daysRef === 365))
						.map((plan) => (
							<div
								key={plan.id}
								className={`planBox`}
								style={{
									width: isMobile() ? "90%" : "100%",
									maxWidth: "600px",
									padding: "16px",
								}}>
								<h3 className="planTitle" style={{ textAlign: "center" }}>
									{capitalizeFirstLetter(plan.title)}
								</h3>
								<p className="planSubs" style={{ textAlign: "center" }}>
									{subtitles[plan.title]}
								</p>
								<Accordion
									expanded={expanded === plan.title}
									onChange={(event, isExpanded) => {
										event.stopPropagation();
										setExpanded(isExpanded ? plan.title : "");
									}}>
									<AccordionSummary
										className="accordionContainer"
										expandIcon={<img src={arrow} alt="arrowImg" />}
										aria-controls="panel-content"
										id="panel-header"
										style={{
											width: "100%",
										}}>
										<div
											className="detailsContainer"
											style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
											<p className="planPrice" style={{ fontSize: "1.2rem"}}>
												{(plan.mountPrice / 100).toLocaleString("pt-BR", {
													style: "currency",
													currency: "BRL",
												})}
												<span>{selectedPlan === "Mensal" ? "/mês" : "/ano"}</span>
											</p>
											<p
												className="planDetails"
												style={{
													fontSize: "1rem",
													color: "#3688EB",
													cursor: "pointer",
													margin: 0,
													marginRight: "8px",
												}}>
												Detalhes
											</p>
										</div>
									</AccordionSummary>
									<AccordionDetails style={{ backgroundColor: "white" }}>
										<p className="planDescription">
											{plan.description.split("\n").map((line, index) => (
												<React.Fragment key={index}>
													<img src={checked} alt="checkedIcon" /> {line}
													<br />
												</React.Fragment>
											))}
										</p>
									</AccordionDetails>
								</Accordion>
								<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
									<GlobalButton 
										type="button" 
										variant="contained" 
										title="Contratar Plano" 
										className="btn-color-primary btn-default btn-w-80"
										handleClick={() => handleClick(plan)}
									/>	
								</div>
							</div>
						))}
				</div>

				<p className="privacyPolicy">
					Ao clicar em “Contratar Plano” você concorda com nossa{" "}
					<span style={{ color: "#3688EB", cursor: "pointer", fontSize: "16px" }} onClick={handleOpen}>
						Política de Privacidade
					</span>
					.
				</p>

				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description">
					<Box sx={{ ...style, width: 400, whiteSpace: "pre-line" }}>
						<h2 id="parent-modal-title">Política de Privacidade</h2>
						<p id="parent-modal-description">{PrivacyPolicy}</p>
					</Box>
				</Modal>
			</div>
		</>
	);
};

export default ProfilePritPlan;
