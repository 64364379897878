import React, { useEffect, useState, useContext, createContext } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Dialog, Divider } from "@mui/material";
import useStyles from "./styles";
import CalendarTimeline from "../common/CalendarTimeline";
import { getAuth } from "firebase/auth";
import ProfessionalSelect from "../common/ProfessionalSelect";
import api from "../../services/api";
import { getDatedBookings } from "../../services/scheduleService";
import AddIcon from "@mui/icons-material/Add";
import { updateInfoServices } from "../../store/redux/modules/services";
import { businessServicesLastUpdate, checkNewsServices, sectorLastUpdate } from "../../services/ServicesService";
import _ from "lodash";

import NewScheduleDialog from "../newScheduleDialog";

import clientAction from "../../store/actions/clientAction";
import sectorAction from "../../store/actions/sectorAction";
import businessAction from "../../store/actions/businessAction";
import { fetchPriceTypesSuccess } from "../../store/actions/priceTypesAction";
import { getProfessionals } from "../../store/actions/professionalsAction";
import { resetServices } from "../../store/reducers/businessServicesSelectReducer";

import "../../styles/schedule/index.scss";
import { useBusiness } from "../../context/business.context";
import Sidebar from "../sidebar";

import SchedulePages from "../../views/SchedulePages";

import { useConfig } from "context/config.context";

const ServicesContext = createContext();

const Schedule = () => {
	const {
		businessData,
		setBusinessData,
		setReservationDates,
		pageType,
		date,
		now,
		setNow,
		setHours,
		setDate,
		setScheduleBreakDates,
	} = useBusiness();
	const { handleFeedback, handleLoading } = useConfig();

	const [auth, setAuth] = useState(getAuth());
	const dispatch = useDispatch();
	const classes = useStyles();
	const navigate = useNavigate();
	const [update, setUpdate] = useState(false);

	const { priceTypes } = (store) => store.priceTypes;

	const [professionals, setProfessionals] = useState([]);
	const [notifications, setNotifications] = useState([]);

	const [, setNewsCount] = useState(0);
	const [lastReminderCheckDate, setLastReminderCheckDate] = useState(new Date(0));
	const [reservations, setReservations] = useState([]);
	const [scheduleBreak, setScheduleBreak] = useState([]);

	const [selectedBooking, setSelectedBooking] = useState(null);
	const [, setOpenDescription] = useState(false);
	const [, setSelectedBookingTransaction] = useState(null);
	const [, setSelectedPaymentList] = useState(null);
	const [filtredProfessional, setFiltredProfessional] = useState(null);
	const [, setReservationsLoading] = useState(true);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [transactionLoading, setTransactionLoading] = useState(false);
	const [, setOpenAppRedirectModal] = useState(false);
	const [, setAppRedirectMessage] = useState("");
	const [myProfessionalId, setMyProfessionalId] = useState(null);
	const [, setOpenFreeTest] = useState(false);
	const [, setReaminingFreeTestDays] = useState(0);
	const [openNewSchedule, setOpenNewSchedule] = useState(false);
	const [contextServices, setContextServices] = useState([]);
	const { business } = useSelector((store) => store.business);
	const { services } = useSelector((store) => store);

	const updateService = (updatedService) => {
		setContextServices((prevServices) =>
			prevServices.map((service) => (service.id === updatedService.id ? updatedService : service))
		);
	};

	useEffect(() => {
		window.addEventListener("resize", (e) => {
			setUpdate(!update);
		});
	}, []);

	const getMyProfessionalId = (me, professionals, business) => {
		professionals.forEach((professional) => {
			if (
				me.id !== business.ownerId &&
				professional.phone === me.phone &&
				(professional.permission === "owner" || professional.permission === "manager")
			) {
				setMyProfessionalId(professional.id);
			}
		});
	};

	const fetchReservations = async (businessId, date, setReservations, handleLoading) => {
		try {
			const formattedDate = moment(date).format("YYYY-MM-DD");
			const nextDay = moment(date).add(1, "days").format("YYYY-MM-DD");
			const response = await getDatedBookings(businessId, formattedDate, nextDay);
			setReservations(response.data.bookings);
			setScheduleBreak(response.data.scheduleBreaks);
		} catch (error) {
			setAppRedirectMessage(
				"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
			);
			setOpenAppRedirectModal(true);
			setReservationsLoading(false);
			console.error(error);
		} finally {
			handleLoading(false);
		}
	};

	useEffect(() => {
		if (businessData) {
			dispatch(businessAction.getBusiness());
			dispatch(clientAction.getClients(businessData?.id, myProfessionalId));
			dispatch(sectorAction.getSector(businessData?.id));
			api
				.get(`subscription?business_id=${businessData.id}`)
				.then((res) => {
					const finalFreeTestData = new Date(businessData.createdAt);
					finalFreeTestData.setDate(finalFreeTestData.getDate() + 7);
					if (finalFreeTestData.getTime() > Date.now()) {
						setReaminingFreeTestDays(Math.floor((finalFreeTestData.getTime() - Date.now()) / 86400000));
						setOpenFreeTest(true);
					}
				})
				.catch((err) => {});
			api
				.get("feed?page=1&per_page=20")
				.then((res) => {
					setNotifications(res.data);
				})
				.catch((err) => {});
		}
	}, [businessData]);

	useEffect(() => {
		const arr = [];
		for (var i = 0; i < 24; i++) {
			arr.push(i);
		}
		setHours(arr);
	}, []);

	setTimeout(() => {
		setInterval(() => {
			setNow(new Date(Date.now()));
		}, 60000);
	}, now.getSeconds() * 1000);

	useEffect(() => {
		const lastCheck = localStorage.getItem("@prit-web/lastReminderCheck");
		if (lastCheck !== null) {
			setLastReminderCheckDate(new Date(lastCheck));
			let count = 0;
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, []);

	useEffect(() => {
		let count = 0;
		if (notifications) {
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [notifications, lastReminderCheckDate]);

	useEffect(() => {
		handleLoading(true);
		const request = setInterval(() => {
			if (auth?.currentUser) {
				api
					.get(
						"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=true"
					)
					.then((res) => {
						console.log("🚀 ~ .then ~ res:", res.data);
						if (res.data.business?.status === "inactive") {
							handleFeedback(
								"Estabelecimento inativo",
								"Atualize seu plano para continuar utilizando o Prit para Profissionais!",
								"warning",
								5000
							);
							window.location.href = "/subscription/onboarding";
						}
						if (!res.data.business) {
							handleLoading(false);
							setBusinessData(null);
							setAppRedirectMessage(
								"Parece que você ainda não possui um estabelecimento... Baixe agora o Prit para Profissionais e crie um novo estabelecimento para você!"
							);
							setOpenAppRedirectModal(true);
						} else {
							setBusinessData(res.data.business);
							setProfessionals(res.data.professionals);
							if (!priceTypes) {
								dispatch(fetchPriceTypesSuccess(res.data.pricingTypes, { type: "FETCH_PRICE_TYPES_SUCCESS" }));
							}
							setScheduleBreakDates(res.data.scheduleBreaksDates);
							dispatch(getProfessionals(res.data.professionals), { type: "GET_PROFESSIONALS" });
						}
						setReservationDates(res.data.bookingDates);
						setReservationsLoading(false);
						setReservations(res.data.bookings);
						getMyProfessionalId(res.data.me, res.data.professionals, res.data.business);
						clearInterval(request);
						storesServices();
					})
					.catch((err) => {
						console.log("🚀 ~ request ~ err:", err);
						setAppRedirectMessage(
							"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
						);
						setOpenAppRedirectModal(true);
						handleLoading(false);
						setReservationsLoading(false);
					});
			} else {
				setAuth(getAuth());
				console.log("🚀 ~ request ~ Login");
				if (auth.currentUser === null) {
					handleLoading(false);
					navigate("/login");
				}
			}
		}, 1000);

		return () => clearInterval(request);
	}, []);

	useEffect(() => {
		setReservationsLoading(true);
	}, [date]);

	useEffect(() => {
		if (businessData?.id) {
			fetchReservations(businessData.id, date, setReservations, handleLoading);
		}
	}, [date]);

	useEffect(() => {
		if (selectedBooking?.bookingTransactionId) {
			api
				.get("booking_transaction/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedBookingTransaction(res.data);
					setTransactionLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setTransactionLoading(false);
				});
		}
		if (selectedBooking?.bookingTransactionId) {
			api
				.get("payment/list/grouped/payments/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedPaymentList(res.data);
					setPaymentLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setPaymentLoading(false);
				});
		}
	}, [selectedBooking, transactionLoading, paymentLoading]);

	useEffect(() => {
		if (selectedBooking && !transactionLoading && !paymentLoading) {
			setOpenDescription(true);
		}
	}, [transactionLoading, paymentLoading]);

	const events = reservations?.map((booking) => ({
		id: booking?.id,
		title: `${booking?.service?.name} com ${booking?.professional?.name}`,
		start: new Date(booking?.startTime),
		end: new Date(booking?.endTime),
		resource: booking,
		color: booking?.professional?.color,
		...booking,
	}));

	const scheduleBreakList = scheduleBreak?.map((breakItem) => {
		const foundProfessional = professionals.find((p) => p.id === breakItem.professionalId);
		return {
			id: breakItem.id,
			professionalName: foundProfessional ? foundProfessional.name : "",
			title: foundProfessional ? `Horário Bloqueado - ${foundProfessional.name}` : "Horário Bloqueado",
			start: new Date(breakItem.startTime),
			end: new Date(breakItem.endTime),
		};
	});

	const selectedServices = () => {
		resetServices(services.categories, services.services, services.businessServices)(dispatch);
	};

	useEffect(() => {
		if (!_.isEmpty(services.services) && !_.isEmpty(services.categories)) {
			selectedServices();
		}
	}, [services.services, services.categories]);

	const storesServices = () => {
		const arrayIds = business.categories.map((item) => item.id);
		businessServicesLastUpdate(business.id, null, {}, false).then((resu) => {
			updateInfoServices(resu.data, null, "BUSINESS_SERVICES_UPDATE_SUCCESS")(dispatch);
		});
		checkNewsServices(arrayIds, null, {}, business.language, false).then((result) => {
			updateInfoServices(result.data, null, "SERVICES_UPDATE_SUCCESS")(dispatch);
		});
		sectorLastUpdate(business.id, null, business.language).then((resul) => {
			const allCategories = resul.data.flatMap((item) => item.categories);
			const sortCategories = allCategories.sort((a, b) => a.name.localeCompare(b.name));
			updateInfoServices(null, sortCategories, "CATEGORIES_UPDATE_SUCCESS")(dispatch);
		});
		handleLoading(false);
	};

	return (
		<ServicesContext.Provider value={{ contextServices, setContextServices, updateService }}>
			<div className={classes.calendarContainer}>
				<Sidebar />

				<Divider orientation="vertical" flexItem className="divider" />

				<div className={classes.reservationList}>
					{pageType === "root" ? (
						<>
							<div
								className="date-header"
								style={{ width: "95%", alignItems: "center", justifyContent: "space-between" }}>
								{professionals?.length > 0 && (
									<div style={{ display: "flex", marginLeft: "30px" }}>
										<ProfessionalSelect name="Todos" onClick={() => setFiltredProfessional(null)} />
										<div style={{ display: "flex", padding: "15px" }}>
											{professionals.map((professional) => (
												<ProfessionalSelect
													key={professional.id}
													name={professional.name}
													onClick={() => setFiltredProfessional(professional)}
													selected={filtredProfessional?.id === professional?.id}
												/>
											))}
										</div>
									</div>
								)}
								<div>
									<button className="newBooking" onClick={() => setOpenNewSchedule(true)}>
										Adicionar um evento +
									</button>
								</div>
							</div>
							<Divider />
							<div>
								<CalendarTimeline
									events={events}
									breaks={scheduleBreakList}
									date={date}
									reservations={reservations}
									onNavigate={(newDate) => setDate(newDate)}
								/>
							</div>

							<button className="floatingButton" onClick={() => setOpenNewSchedule(true)}>
								<AddIcon style={{ fontSize: "30px" }} />
							</button>
						</>
					) : (
						<SchedulePages type={pageType} />
					)}
				</div>
				<Dialog
					open={openNewSchedule}
					fullScreen
					PaperProps={{
						style: { backgroundColor: "transparent" },
					}}>
					<NewScheduleDialog date={date} onClose={() => setOpenNewSchedule(false)}></NewScheduleDialog>
				</Dialog>
			</div>
		</ServicesContext.Provider>
	);
};

export const useServicesContext = () => useContext(ServicesContext);
export default Schedule;
