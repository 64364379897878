import api from "./api";

export const getBusiness = () => {
	return api.get("me/businesses");
};

export const getBusinessPhotos = () => {
	return api.get("me/businesses");
};

export const getSchedule = () => {
	return api.get("schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=true")
}

export const putBusinessAddress = (id, address) => {
	return api.put(`business/${id}/address`, address);
};

export const postBusiness = (data) => {
	return api.post(`business`, data);
};

export const putBusiness = (id, data) => {
	return api.put(`business/${id}`, data);
};

export const updateUser = (id, data) => {
	return api.patch(`user/${id}`, data);
};

export const uploadImage = (data) => {
	return api.post("upload", data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const getPhotos = (id) => {
	return api.get(`photo/${id}`);
};

export const deletePhoto = (id) => {
	return api.delete(`photo/${id}`);
};

export const sendPhotos = (data) => {
	return api.post("photo", data);
};

export const updatePhotos = (data, business_id) => {
	return api.patch(`photo/business/${business_id}`, data);
};

export const getEarlyBookings = () => {
	return api.get(`early_booking`);
};

export const putBusinessHours = (data, id) => {
	return api.put(`/business/${id}/business_hours_time`, data);
};

export const businessPlan = (businessId) => {
	return api.get(`subscription?business_id=${businessId}`);
};

const businessService = {
	getBusiness,
	getBusinessPhotos,
	putBusinessAddress,
	postBusiness,
	putBusiness,
	getPhotos,
	uploadImage,
	updatePhotos,
	deletePhoto,
	sendPhotos,
	getEarlyBookings,
	putBusinessHours,
};

export default businessService;
