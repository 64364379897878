import * as Yup from "yup";
import { validateCPF } from "utils/validators";

export const creditCardSchema = Yup.object({
	creditCard: Yup.string().required("Número do cartão é obrigatório"),
	name: Yup.string().required("Nome é obrigatório"),
	expiry: Yup.string().required("Data de validade é obrigatório"),
	cvc: Yup.string().required("CVC é obrigatório"),
	cpf: Yup.string()
		.required("Documento é obrigatório")
		.test("cpf", "CPF inválido", (value) => validateCPF(value)),
});

export const initalCreditCardForm = {
	creditCard: "",
	name: "",
	expiry: "",
	cvc: "",
	cpf: "",
};
