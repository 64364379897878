import { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	TodayOutlined,
	HomeWorkOutlined,
	AccessTimeOutlined,
	WorkOutlineOutlined,
	GroupOutlined,
	PersonOutlineOutlined,
	RedeemOutlined,
	QuestionAnswerOutlined
} from "@material-ui/icons";
import { useMixpanel } from "react-mixpanel-browser";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { isMobile } from "../utils/isMobile";
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import Text from "../components/textComponent/text.jsx";

const BusinessContext = createContext();

export function BusinessProvider({ children }) {
	const { business } = useSelector((store) => store.business);
	const mixpanel = useMixpanel();
	const [showCalendar, setShowCalendar] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [sharedDate, setSharedDate] = useState(new Date());

	const [date, setDate] = useState(new Date());
	const [hours, setHours] = useState([]);
	const [now, setNow] = useState(new Date(Date.now()));

	const [pageType, setPageType] = useState("root");
	const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);
	const [isSchedule, setIsSchedule] = useState(pageType === "root");
	const [reservationDates, setReservationDates] = useState([]);
	const [scheduleBreakDates, setScheduleBreakDates] = useState([]);
	const [businessData, setBusinessData] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleAppDownload = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClosePopover = () => {
		setAnchorEl(null);
	};

	const handleSetDate = (e) => {
		setDate(e);
		setPageType("root");
	};

	const handleMenu = (_page) => {
		setPageType(_page);
		if (isMobile()) setShowMenu(!showMenu);
	};

	useEffect(() => {
		if (pageType !== "root") {
			setIsSchedule(false);
		} else {
			setIsSchedule(true);
		}
	}, [pageType]);

	const handleOpenAssistent = () => {
		if (business.virtualAssistantLink) {
			window.open(business.virtualAssistantLink);
		}
	};

	const Menus = [
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Agenda</Text>,
			page: "root",
			icon: <TodayOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				setIsSchedule(true);
				handleMenu("root");
			},
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Assistente Virtual - Prit</Text>,
			icon: <SmartToyOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				handleOpenAssistent();
				mixpanel.track("Assistente virtual IA - WEB");
			},
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Perfil da empresa</Text>,
			page: "businessProfile",
			icon: <HomeWorkOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("businessProfile"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Horário de funcionamento</Text>,
			page: "openingHours",
			icon: <AccessTimeOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("openingHours"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Serviços</Text>,
			page: "services",
			icon: <WorkOutlineOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("services"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Profissionais</Text>,
			page: "professionals",
			icon: <PersonOutlineOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("professionals"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Clientes</Text>,
			page: "listClients",
			icon: <GroupOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("listClients"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Assinatura</Text>,
			page: "profilePritPlan",
			icon: <RedeemOutlined style={{ fontSize: 20, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => handleMenu("profilePritPlan"),
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Preciso de ajuda</Text>,
			icon: <QuestionAnswerOutlined style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => {
				window.open(
					`https://wa.me/${process.env.REACT_APP_WHATSAPP_HELP_NUMBER}/?text=Olá!%20Vim%20do%20Prit%20WEB%20gostaria%20de%20ajuda`,
					"_blank"
				);
			},
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Baixe o nosso App e tenha acesso a todas as funcionalidades!</Text>,
			icon: <InstallMobileOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }}/>,
			action: (e) => {
				handleAppDownload(e);
			},
		},
		{
			title: <Text variant={'sideBar'} style={{color: '#A9A9A9'}}>Sair</Text>,
			icon: <LogoutOutlinedIcon style={{ fontSize: 18, marginRight: "10px", color: "#B5C0D0" }} />,
			action: () => setIsOpenModalLogout(true),
		},
	];

	return (
		<BusinessContext.Provider
			value={{
				business,
				showCalendar,
				setShowCalendar,
				date,
				setDate,
				hours,
				setHours,
				now,
				setNow,
				pageType,
				setPageType,
				handleSetDate,
				Menus,
				isOpenModalLogout,
				setIsOpenModalLogout,
				anchorEl,
				setAnchorEl,
				handleClosePopover,
				reservationDates,
				setReservationDates,
				scheduleBreakDates,
				setScheduleBreakDates,
				businessData,
				setBusinessData,
				showMenu,
				toggleMenu,
				sharedDate,
				setSharedDate,
				isSchedule,
				setIsSchedule,
			}}>
			{children}
		</BusinessContext.Provider>
	);
}

export function useBusiness() {
	const context = useContext(BusinessContext);
	return context;
}
